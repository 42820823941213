import { auth, firestore, firebaseInstance } from "./FirebaseService";

import { AxiosError, AxiosResponse } from "axios";

import { log } from "../utils/functions";
import { genericGet, genericPatch } from "../utils/backendRequest";
import { endpointsQuery } from "../utils/endpoints";
import { IEditUserParams, IUser } from "../models/interfaces/UserInterface";
import { AuthServices } from "./AuthServices";

const UserServices = {
  getClientLocation: async (overridedRegion?: string) => {
    try {
      const ip_request = await fetch("https://api.ipify.org?format=json");
      const ip = await ip_request.json();

      const request = await fetch(
        "https://api.ipapi.com/api/" + ip['ip'] + "?access_key=168ce034987bb53eb8ef5b77c8e086f7"
      );
      if (overridedRegion === "null") {
        localStorage.removeItem("overridedRegion");
      }

      const location = await request.json();

      const storagedRegion =
        overridedRegion ?? localStorage.getItem("overridedRegion");

      const response =
        storagedRegion === null || storagedRegion === "null"
          ? await AuthServices.getCountryPlans(location.country_code)
          : { enabled: false, plans: [] };

      if (overridedRegion && overridedRegion !== "null") {
        localStorage.setItem("overridedRegion", overridedRegion);
      }

      return {
        ...location,
        country_code: location.country_code,
        isServiceEnabled: response.enabled,
        countryPlans: response.plans,
      };
    } catch (e: any) {
      log("Error @IPAPI", "error");
      log(e, "error");

      return {
        ip: "127.0.0.1",
        country_code: "XY",
      };
    }
  },
  log: (file: any, data: any) => {
    return new Promise<void>(async (resolve, reject) => {
      const currentTime =
        firebaseInstance.firestore.FieldValue.serverTimestamp();
      const user = auth.currentUser as any;
      const docRefCollection = firestore
        .collection("user_log")
        .where("uid", "==", user.uid)
        .where("file", "==", file)
        .limit(1);
      docRefCollection.get().then((querySnapshot) => {
        if (querySnapshot.empty) {
          firestore.collection("user_log").add({
            uid: user.uid,
            file: file,
            data: data,
            created_at: currentTime,
            updated_at: currentTime,
          });
        } else {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
              uid: user.uid,
              file: file,
              data: data,
              created_at: currentTime,
              updated_at: currentTime,
            });
          });
        }
      });
      resolve();
    });
  },
  getLog: (file: any) => {
    // return new Promise(async (resolve, reject) => {
    //   const user = auth.currentUser as any;
    //   const docRefCollection = firestore
    //     .collection("user_log")
    //     .where("uid", "==", user.uid)
    //     .where("file", "==", file)
    //     .limit(1);
    //   docRefCollection.get().then((querySnapshot) => {
    //     if (querySnapshot.empty) {
    //       resolve(null);
    //     } else {
    //       querySnapshot.forEach((doc) => {
    //         resolve(doc.data());
    //       });
    //     }
    //   });
    // });
    // TODO: Get movie time from last seen
    return new Promise((resolve) => resolve(null));
  },
  getUser: async (
    token: string,
    userId: string
  ): Promise<AxiosResponse<IUser, AxiosError>> => {
    const response = await genericGet(
      endpointsQuery.getUser.replace("{userId}", userId.toString()),
      undefined,
      undefined,
      token
    );
    return response.data;
  },
  editUser: async (
    params: IEditUserParams,
    token: string
  ): Promise<AxiosResponse<{ message: string; user: IUser }, AxiosError>> => {
    let user: Record<string, any> = {};
    if (params?.lastName) {
      user["last_name"] = params.lastName;
    }
    if (params?.name) {
      user["first_name"] = params.name;
    }
    if (params?.language) {
      user["language"] = params.language;
    }

    const response = await genericPatch(
      endpointsQuery.getUser.replace("{userId}", params.userId.toString()),
      undefined,
      {
        user,
      },
      token
    );
    return response;
  },
};

export default UserServices;
